<template>
    <div class="main-navigation">
        <!-- Level 1 menu -->
        <div class="main-navigation__level-1" v-if="data.length === 3" data-aos="fade">
            <div v-for="menuItem in data" :key='menuItem.id'>
                <div class="main-navigation__level-1--title" :class="{'main-navigation__level-1--active':expandedSection===menuItem.name}" @click="expandSection(menuItem)">{{menuItem.name}}</div>
                <transition name='accordion' v-on:before-enter="beforeEnterV" v-on:enter="enterV" v-on:before-leave="beforeLeaveV" v-on:leave="leaveV">
                    <div v-if="menuItem.children" class="main-navigation__level-1--section" v-show="expandedSection===menuItem.name">
                        <div class="main-navigation__level-1--subtitle" :class="{'main-navigation__level-1--open-level-2':(selectedSectionItem?selectedSectionItem.id:null)===item.id}" v-for="item in menuItem.children" :key="item.id" @click="selectSectionItem(item)">
                            <div class="upper-cutout"></div>
                            <div class="lower-cutout"></div>
                            {{item.name}}
                        </div>
                    </div>
                </transition>
            </div>
        </div>
        <!-- Level 2 menu -->
        <div class="main-navigation__level-2-editor" v-show="showLevelTwo">
            <div v-if="selectedSectionItem" class="main-navigation__level-2-editor--wrapper">
                <template-category @select="selectEditor($event)" :data="selectedSectionItem" />
            </div>
        </div>
        <div class="close-button--shadow" v-if="selectedSectionItem && selectedSectionItem.name !== 'Page'">
            <div class="close-button--shape" @click="toggleLevelTwo">
                <v-icon color="#D4D9DF">{{showLevelTwo?`mdi-chevron-left`:`mdi-chevron-right`}}</v-icon>
            </div>
        </div>
    </div>
</template>
<script>
import TemplateCategory from './TemplateCategory.vue';
export default {
    data() {
        return {
            data: [],
            expandedSection: null,
            selectedSectionItem: null,
            showLevelTwo: true,
        }
    },
    components: {
        TemplateCategory,
    },
    async mounted() {
        await this.getData();
        this.$root.$on('refetchMenu', () => {
            this.expandedSection = null,
                this.selectedSectionItem = null,
                this.expandedSubSection = null,
                this.selectedSubSectionItem = null,
                this.getData()
        });
    },
    beforeDestroy() {
        this.$root.$off('openEditorLevelTwo');
        this.$root.$off('selectEditor');
    },
    methods: {
        //Expand Root Section
        expandSection(item) {
            this.selectedSectionItem = null;
            if (this.expandedSection === item.name) {
                this.expandedSection = null;
            } else {
                this.expandedSection = item.name;
                this.showLevelTwo = false;
            }
            if (this.expandedSection === this.data[0].name || this.expandedSection === this.data[2].name) {
                this.$root.$emit('openMenu', this.expandedSection);
                this.$router.push(`/main/${this.$account}?menu=${this.expandedSection}`);
            }
        },
        //Select Menu Item in Expanded Root Section
        selectSectionItem(item) {
            this.selectedSectionItem = JSON.parse(JSON.stringify(item));
            this.showLevelTwo = true;
            if (this.selectedSectionItem.name === this.data[1].children[0].name) {
                this.$root.$emit('openEditorLevelTwo', false);
            } else {
                this.$root.$emit('openEditorLevelTwo', true);
            }
            if (this.expandedSection === this.data[2].name) {
                if (item.id !== parseInt(this.$route.params.folders_id)) {
                    this.$router.push('/main/' + this.$account + '/folders/' + item.id);
                }
            }
        },

        selectEditor(item) {

            if(item.type == 'icon') {
                let template = {
                    component: 'v-image',
                    type: 'image',
                    name: 'image1',
                    x: 1000,
                    y: 1200,
                    width: 500,
                    height: 500,
                    stroke: '#000000',
                    opacity: 1,
                    strokeWidth: 0,
                    draggable: true,
                    keepRatio: true,
                    shadowColor: '#000000',
                    shadowBlur: 20,
                    shadowOffsetX: 0,
                    shadowOffsetY: 0,
                    shadowOpacity: 0,
                }
                template.url = "https://chartarium.weareomni.eu/storage/templates" + item.file;
                this.$root.$emit('selectEditor', template)
            } else {
                this.$root.$emit('selectEditor', item.content);
            }

            
        },
        // Toggle Level 2 Menu
        toggleLevelTwo() {
            this.showLevelTwo = !this.showLevelTwo;
            this.$root.$emit('openEditorLevelTwo', this.showLevelTwo);
        },

        async getData() {

            try {

                this.data = [];
                let resource = { id: 'resurse', name: 'resurse chartarium', children: [] }
                let editor = {
                    id: 'editor',
                    name: 'editor resurse',
                    children: [/*{
                            id: 1,
                            name: 'Page',
                            parent_id: null,
                            children: []
                        },
                        {
                            id: 2,
                            name: 'Text',
                            parent_id: null,
                            children: [{
                                    id: 3,
                                    name: 'Adăugați un titlu',
                                    type: 'text',
                                    template: {
                                        component: 'v-text',
                                        type: 'text',
                                        name: 'Text',
                                        x: 1200,
                                        y: 200,
                                        text: 'Simple Text',
                                        fontSize: 250,
                                        fontFamily: 'Calibri',
                                        verticalAlign: 'left',
                                        fontStyle: 'bold',
                                        textDecoration: '',
                                        fill: '#FF43C7FF',
                                        stroke: '#000',
                                        strokeWidth: 0,
                                        draggable: true,
                                        keepRatio: true,
                                        shadowColor: '#000000',
                                        shadowBlur: 20,
                                        shadowOffsetX: 0,
                                        shadowOffsetY: 0,
                                        shadowOpacity: 0,
                                    },
                                },
                                {
                                    id: 4,
                                    name: 'Adăugați continut',
                                    type: 'text',
                                    template: {
                                        component: 'v-text',
                                        type: 'text',
                                        name: 'Text',
                                        fill: 'red',
                                        x: 1200,
                                        y: 600,
                                        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam congue eros a ornare mollis. Pellentesque hendrerit elit ac laoreet porta. Nam sit amet ligula id magna egestas mattis. Maecenas eget tortor justo. Suspendisse porta lorem id dictum pretium. Sed fringilla et orci fermentum finibus. Cras tincidunt commodo orci vitae ultricies.',
                                        verticalAlign: 'left',
                                        width: 1200,
                                        fontSize: 90,
                                        fontFamily: 'System',
                                        fontStyle: '',
                                        textDecoration: '',
                                        fill: '#000000FF',
                                        stroke: '#000',
                                        strokeWidth: 0,
                                        draggable: true,
                                        keepRatio: true,
                                        shadowColor: '#000000',
                                        shadowBlur: 20,
                                        shadowOffsetX: 0,
                                        shadowOffsetY: 0,
                                        shadowOpacity: 0,
                                    },
                                },
                            ]
                        },
                        {
                            id: 5,
                            name: 'Forme',
                            parent_id: null,
                            type: 'shape',
                            children: [{
                                    id: 6,
                                    name: 'Patrat',
                                    type: 'shape',
                                    template: {
                                        component: 'v-rect',
                                        type: 'rectangle',
                                        name: 'Rectangle1',
                                        rotation: 0,
                                        x: 10,
                                        y: 810,
                                        fill: '#FF43C7FF',
                                        stroke: '#000',
                                        cornerRadius: 0,
                                        strokeWidth: 0,
                                        width: 900,
                                        height: 900,
                                        draggable: true,
                                        shadowColor: '#000000',
                                        shadowBlur: 10,
                                        shadowOffsetX: 40,
                                        shadowOffsetY: 40,
                                        shadowOpacity: 1,
                                    }
                                },
                                {
                                    id: 7,
                                    name: 'Cerc',
                                    type: 'shape',
                                    template: {
                                        component: 'v-circle',
                                        type: 'circle',
                                        name: 'Circle1',
                                        x: 800,
                                        y: 800,
                                        radius: 350,
                                        fill: '#1AA7A7FF',
                                        stroke: '#000',
                                        strokeWidth: 0,
                                        draggable: true,
                                        shadowColor: '#000000',
                                        shadowBlur: 50,
                                        shadowOffsetX: 40,
                                        shadowOffsetY: 40,
                                        shadowOpacity: 1,
                                    }
                                },
                                {
                                    id: 8,
                                    name: 'Poligon',
                                    type: 'shape',
                                    template: {
                                        component: 'v-regular-polygon',
                                        type: 'polygon',
                                        name: 'Polygon',
                                        x: 400,
                                        y: 900,
                                        sides: 6,
                                        radius: 350,
                                        fill: '#615BFF6A',
                                        stroke: '#000',
                                        strokeWidth: 30,
                                        draggable: true,
                                        shadowColor: '#000000',
                                        shadowBlur: 10,
                                        shadowOffsetX: 40,
                                        shadowOffsetY: 40,
                                        shadowOpacity: 1,
                                    }
                                },
                                {
                                    id: 9,
                                    name: 'Stea',
                                    type: 'shape',
                                    template: {
                                        component: 'v-star',
                                        type: 'star',
                                        name: 'Star',
                                        x: 400,
                                        y: 500,
                                        numPoints: 6,
                                        innerRadius: 350,
                                        outerRadius: 200,
                                        fill: '#FFEC00FF',
                                        stroke: '#000',
                                        strokeWidth: 30,
                                        draggable: true,
                                        shadowColor: '#000000',
                                        shadowBlur: 10,
                                        shadowOffsetX: 40,
                                        shadowOffsetY: 40,
                                        shadowOpacity: 1,
                                    }
                                },
                                {
                                    id: 10,
                                    name: 'Linie',
                                    type: 'shape',
                                    template: {
                                        component: 'v-line',
                                        type: 'line',
                                        name: 'Line',
                                        points: [0, 0, 500, 0],
                                        stroke: '#FF43C7FF',
                                        strokeWidth: 50,
                                        x: 1200,
                                        y: 500,
                                        draggable: true,
                                        shadowColor: '#000000',
                                        shadowBlur: 0,
                                        shadowOffsetX: 40,
                                        shadowOffsetY: 40,
                                        shadowOpacity: 0,
                                    }
                                }
                            ]
                        },
                        {
                            id: 11,
                            name: 'Imagini',
                            parent_id: null,
                            type: 'shape',
                            children: [{
                                id: 12,
                                name: 'Adauga o imagine',
                                type: 'shape',
                                template: {
                                    component: 'v-image',
                                    type: 'image',
                                    name: 'image1',
                                    x: 1000,
                                    y: 1200,
                                    width: 1000,
                                    height: 1000,
                                    stroke: '#000000',
                                    opacity: 1,
                                    strokeWidth: 0,
                                    url: "/yoda.jpg",
                                    draggable: true,
                                    keepRatio: true,
                                    shadowColor: '#000000',
                                    shadowBlur: 20,
                                    shadowOffsetX: 0,
                                    shadowOffsetY: 0,
                                    shadowOpacity: 0,
                                }
                            }]
                        }*/
                    ]
                }

                let response = await this.$axios.get('/template-categories');

                editor.children.push(...response.data);


                let folder = { id: 'dosare', name: 'dosarele mele', children: [] }

                this.data.push(resource);
                this.data.push(editor);
                this.data.push(folder);

                if (this.$route.name === 'Editor Resurse') {
                    this.expandedSection = this.data[1].name;
                    this.selectedSectionItem = this.data[1].children[0];
                }

            } catch (error) {
                console.log(error);
            }


        },

        //Animation functions
        beforeEnterV: function(el) {
            el.style.height = '0';
        },
        enterV: function(el) {
            el.style.height = el.scrollHeight + 16 + 'px';
        },
        beforeLeaveV: function(el) {
            el.style.height = el.scrollHeight + 16 + 'px';
        },
        leaveV: function(el) {
            el.style.height = '0';
        },
        beforeEnterH: function(el) {
            el.style.width = '0';
        },
        enterH: function(el) {
            el.style.width = '290px';
        },
        beforeLeaveH: function(el) {
            el.style.width = '290px';
        },
        leaveH: function(el) {
            el.style.width = '0';
        }
    }
}
</script>