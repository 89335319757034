<template>
    <div class="template-category" style="margin-top: -15px;">
        <div class="category" v-for="category in data.children">
            <div class="title" style="margin-bottom: 10px;">
                {{ category.name }}
            </div>
            <div class="templates">
                <v-row>
                    <v-col cols="6" v-for="template in category.templates">
                        <div @click="$emit('select', template)" class="template-holder" v-ripple>
                            <img :src="'https://chartarium.weareomni.eu/storage/templates' + template.file"  alt="" width="100%">
                            <div class="name">
                                {{ template.name }}
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </div>
        <div class="category general" v-if="data.templates.length > 0">
            
            <div class="templates">
                <v-row>
                    <v-col cols="6" v-for="template in data.templates">
                        <div @click="$emit('select', template)" class="template-holder" v-ripple>
                            <img :src="'https://chartarium.weareomni.eu/storage/templates' + template.file"  alt="" width="100%">
                            <div class="name">
                                {{ template.name }}
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </div>
            
        </div>
    </div>
</template>
<style lang="scss">
.templates {
    margin-bottom: 15px;
}

.main-navigation__level-2-editor {
    height: calc(100vh - 95px);
    overflow-y: scroll;
    overflow-x: hidden;
}

.template-holder {
    padding: 10px 25px 25px 25px;
    background-color: #DBDCE0;
    border-radius: 6px;
    position: relative;

    .title {}


    .name {
        font-size: 14px;
        padding: 3px 0px 0px 0px;
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        ;
        text-align: center;
        text-transform: uppercase;
        font-weight: 500;
        background-color: rgba(0, 0, 0, 0.6);
        color: #fff;
        border-radius: 0px 0px 6px 6px;

    }

}
</style>
<script>
export default {
    props: ['data'],

}
</script>