<template>
  <div class="h-full">
    <Navbar v-if="!this.editor"/>
    <NavbarEditor v-if="this.editor"/>
    <div class="main">
      <Menu v-if="!this.editor"/>
      <MenuEditor v-if="this.editor"/>
      <div class="main__view">
        <router-view :key="$route.path"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/organisms/Navbar.vue";
import Menu from "../components/organisms/Menu.vue";
import NavbarEditor from "../components/organisms/NavbarEditor.vue";
import MenuEditor from "../components/organisms/MenuEditor.vue";
export default {
  components: {
    Navbar,
    Menu,
    NavbarEditor,
    MenuEditor
  },
  data(){
    return {
      editor:false
    }
  },
  watch: {
    "$route": function(params){
      if(params.name === 'Editor Resurse'){
        this.editor = true;
      } else {
        this.editor = false;
      }
    }
  },
  mounted(){
    if(this.$route.name === 'Editor Resurse'){
      this.editor = true;
    } else {
      this.editor = false;
    }
  },
  methods:{
    
  }
}
</script>

